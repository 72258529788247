<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
cols="12"
md="6"
class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
>
            <label>  {{ $i18n.t('Show') }}</label>
            <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $i18n.t('entries') }}</label>
          </b-col>
          <b-col
cols="12"
md="6"
>
            <div class="d-flex align-items-center justify-content-end">
              <b-button
variant="primary"
:to="{ name:'roles-add' }"
>
                <span class="text-nowrap">{{ $i18n.t('Add Role') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <loading :show="is_loading">
        <b-table
            hover
            responsive="sm"
            :items="items"
            :fields="fields"
            show-empty
            :empty-text="$i18n.t('there_are_no_data')"
        >
          <!-- Column: Actions -->
          <template
v-if="can('roles','update') || can('roles','delete')"
#cell(actions)="data"
>
            <b-dropdown
                variant="link"
                no-caret
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
v-if="can('roles','update')"
:to="{ name: 'roles-edit', params: { id: data.item.id } }"
>
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
v-if="can('roles','delete')"
@click="confirmDelete(data.item)"
>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </loading>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="page"
                :total-rows="totalPages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      totalPages: 1,
      page: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      items: [],
      isModalSidebarActive: false,
      isUpdateForm: false,
      oldUserData: null,
      is_loading: false,
      repository: RepositoryFactory.create('roles'),
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'id',
          label: "#",
          sortable: true
        },
        {
          key: 'name',
          label: this.$i18n.t('Name'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions'),
          sortable: false,
        }
      ]
    }
  },
  watch: {
    perPage() {
      this.getData()
    },
    page() {
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.is_loading = true
      const params = {
        page: this.page,
        perPage: this.perPage,
      }
      this.repository.getAll(params)
          .then(res => {
            const response = res.data
            const data = []
            response.data.forEach((item, index) => {
              const permissions = []
              item.permissions.forEach((permission) => {
                permissions.push(permission.name)
              })
              const permissionsName = permissions.join(" , ")
              data[index] = response.data[index]
              data[index].permissions = permissionsName
            });
            this.items = data
            this.totalPages = response.meta.total
            this.is_loading = false
          })
    },
    refetchData() {
      this.getData()
    },
    activeEditForm(item) {
      this.isUpdateForm = true
      this.oldUserData = item
      this.isModalSidebarActive = true
    },
    confirmDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.is_loading = true
          this.repository.delete(item.id).then(response => {
            this.refetchData()
            this.$swal({
              icon: 'success',
              title: `${this.$i18n.t('Deleted')}!`,
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          this.is_loading = false
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
